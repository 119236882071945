export default {
    getInterests(state) {
        return state.interests;
    },
    getLanguages(state){
        return state.languages;
    },
    getGlocalFeatures(state){
        return state.features;
    },
    getShareArticle(state){
        return state.shareArticles;
    },
    getShareArticleNextUrl(state){
        return state.shareArticleNextUrl;
    },
    getShareArticlePreviousUrl(state){
        return state.shareArticlepreviousUrl;
    },
    getShareTotalArticles(state){
        return state.shareTotalArticles;
    },
    getRatedArticle(state){
        return state.ratedArticles;
    },
    getRatedArticleNextUrl(state){
        return state.ratedArticleNextUrl;
    },
    getRatedArticlePreviousUrl(state){
        return state.ratedArticlepreviousUrl;
    },
    getRatedTotalArticles(state){
        return state.ratedTotalArticles;
    },
    getInterestGroupId(state){
        return state.interestGroupId;
    },
    getInterestGroup(state){
        return state.interestGroup;
    },
    getPrivateDiscussionComments(state){
        return state.privateTopicComments
    },
    getPublicDiscussionComments(state){
        return state.publicTopicComments
    },
    getActiveReactionId(state){
        return state.activeReactionId.id
    },
    getReactedArticle(state){
        return state.reactedArticle;
    },
    getSavedArticle(state){
        return state.savedArticles;
    },
    getArticlesFolders(state){
        return state.articlesFodlers;
    },
    getJoinedArticleFolders(state){
        return state.joinedFolders;
    }
}