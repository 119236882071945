export default {
  feeds(state) {
    return state.feeds;
  },
  isLoading(state) {
    return state.loading;
  },
  error(state) {
    return state.loading;
  },
};
