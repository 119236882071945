import ApiService from "../../../services/api.service.js";
import Cookies from "js-cookie";
export default {
  async getProfileInfo(context) {
    const user_id = Cookies.get("user_id");
    return new Promise((resolve, reject) => {
      ApiService.get("user/profile/" + user_id)
        .then((response) => {
          if (response.status === 200) {
            // context.commit("setProfileInfo", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /********Certificates *******************/
  loadCertificates(context, payload) {
    // if (context.getters.getCertificates.length == 0) {
    return new Promise((resolve, reject) => {
      ApiService.get("user/profile/certificates?user_id=" + payload.user_id)
        .then((response) => {
          context.commit("setCertificates", response.data);
          context.commit("updateTimeline");
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
    // }
  },
  addCertificate(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`user/profile/certificates/add/`, payload)
        .then((response) => {
          if (response.status === 201) {
            let certificate = {
              id: response.data.id,
              title: response.data.title,
              issuing_organization: response.data.issuing_organization,
              issue_date: response.data.issue_date,
              expire_date: response.data.expire_date,
              does_not_expire: response.data.does_not_expire,
              credential_id: response.data.credential_id,
              credential_url: response.data.credential_url,
            };
            context.commit("addCertificate", certificate);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCertificate(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `/user/profile/certificates/update/${payload.id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("updateCertificate", payload);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteCertificate(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`user/profile/certificates/delete/${payload}/`)
        .then((response) => {
          if (response.status === 204) {
            context.commit("deleteCertificate", payload);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /*************************Experience ********************/
  loadExperiences(context, payload) {
    context.commit("setExperienceLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get(
        "user/profile/experience/list/?user_id=" +
          payload.user_id +
          "&experience_type=work"
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("setExperienceLoader", false);
            context.commit("setExperiences", response.data);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    // }
  },
  addExperience(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`user/profile/experience/add/`, payload)
        .then((response) => {
          if (response.status === 201) {
            let experience = {
              id: response.data.id,
              title: response.data.title,
              organization: response.data.organization,
              start_date: response.data.start_date,
              end_date: response.data.end_date,
              present: response.data.present,
              description: response.data.description,
              duration: response.data.duration,
            };
            context.commit("addExperience", experience);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updateExperience(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`/user/profile/experience/${payload.id}/update/`, payload)
        .then((response) => {
          if (response.status === 200) {
            let updatedPayload = {
              ...response.data,
              id: payload.id,
            };
            context.commit("updateExperience", updatedPayload);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteExperience(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`user/profile/experience/${payload}/delete/`)
        .then((response) => {
          if (response.status === 204) {
            context.commit("deleteExperience", payload);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /***************************Audience Group****************************** */
  loadGroups(context, payload) {
    // const user_id = Cookies.get("user_id");
    // if (context.getters.getAudienceGroups.length == 0) {
    context.commit("setGroupLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("user/profile/group/?created_by=" + payload.user_id)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setGroupLoader", false);
            context.commit("setAudienceGroups", response.data);
            context.commit("updateTimeline");
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    // }
  },
  addNewAudienceGroup(context, payload) {
    let requestObj = {
      group_name: payload,
    };
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`user/profile/group/add/`, requestObj)
        .then((response) => {
          if (response.status === 201) {
            context.commit("addNewAudienceGroup", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateGroup(context, payload) {
    ApiService.setAuthorizationHeader();
    let requestObj = {
      group_name: payload.group_name,
    };
    return new Promise((resolve, reject) => {
      ApiService.put(`user/profile/group/${payload.id}/update/`, requestObj)
        .then((response) => {
          if (response.status === 200) {
            context.commit("updateAudienceGroup", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteGroup(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`user/profile/group/${payload}/delete/`)
        .then((response) => {
          if (response.status === 204) {
            context.commit("deleteAudienceGroup", payload);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /*************************Topics****************************** */

  addTopic(context, payload) {
    ApiService.setAuthorizationHeader();
    let requestObj = {
      title: payload,
      active: true,
    };
    return new Promise((resolve, reject) => {
      ApiService.post("user/profile/topic/add/", requestObj)
        .then((response) => {
          if (response.status === 201) {
            context.commit("addTopic", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateTopic(context, payload) {
    ApiService.setAuthorizationHeader();
    let requestObj = {
      title: payload.title,
      active: payload.active,
    };
    return new Promise((resolve, reject) => {
      ApiService.put(`/user/profile/topic/${payload.id}/update/`, requestObj)
        .then((response) => {
          if (response.status === 200) {
            context.commit("updateTopic", payload);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteTopic(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`user/profile/topic/${payload}/delete/`)
        .then((response) => {
          if (response.status === 204) {
            context.commit("deleteTopic", payload);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  /*********************Friends*********************** */

  getFriendsList(context, payload) {
    context.commit("setConectionLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get(`user/friends/list/?to_user=${payload.user_id}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setConectionLoader", false);
            context.commit("GET_FRIENDS_LIST", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addFriendToCategory(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post(`user/profile/audience_group/add/`, payload)
        .then((response) => {
          if (response.status === 201) {
            context.commit("addFriendToGroup", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchGroupAudience(context, payload) {
    context.commit("setConectionLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get(`user/profile/audience_group/?group=${payload}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("addAudienceToGroup", response.data);
            context.commit("setConectionLoader", false);
            resolve(response);
          }
        })
        .catch((err) => {
          context.commit("setConectionLoader", false);
          reject(err);
        });
    });
  },
  deleteGroupAudience(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `user/profile/audience_group/${payload.id}/delete/${payload.group}/`
      )
        .then((response) => {
          if (response.status === 204) {
            // context.commit("REMOVE_GROUP_USER", payload.id)
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  /************************Languages********************** */
  getAddedLanguages(context, payload) {
    context.commit("setLanguagesLoader", true);
    return new Promise((resolve, reject) => {
      ApiService.get("user/profile/languages/list/?user_id=" + payload.user_id)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setLanguages", response.data);
            context.commit("setLanguagesLoader", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    // }
  },
  addLanguage(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("user/profile/languages/add/", payload)
        .then((response) => {
          if (response.status === 201) {
            context.commit("addLanguage", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateLanguage(context, payload) {
    ApiService.setAuthorizationHeader();
    let requestObj = {
      code: payload.code,
      proficiency: payload.proficiency,
    };
    return new Promise((resolve, reject) => {
      ApiService.put(`user/profile/languages/update/${payload.id}/`, requestObj)
        .then((response) => {
          if (response.status === 200) {
            context.commit("updateLanguage", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteLanguage(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`user/profile/languages/delete/${payload}/`)
        .then((response) => {
          if (response.status === 204) {
            context.commit("deleteLanguage", payload);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Login User Information

  getUserInformation(context, _1) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("user/me/")
        .then((response) => {
          if (response.status === 200) {
            context.commit("setLoggedInUserDetails", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get user new topics

  getUserTopicsWithOrdering(context, _) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("interest-ordering/list/")
        .then((response) => {
          if (response.status === 200) {
            context.commit("setUsersCategories", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Udpate ordering of user news topic

  async updateTopicsOrdering(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(`interest-ordering/update/${payload.id}/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchProfileInfo(context) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("user/me/")
        .then((response) => {
          if (response.status === 200) {
            context.commit("setProfile", response.data.profile);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateProfileInfo(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.patch("user/me/", payload)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setProfile", response.data.profile);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Get and set Profile and Cover Photo

  fetchProfilePic(_, _1) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("user/me/")
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateProfilePic(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `user/profile/change-image/${payload.get("id")}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("SET_PROFILE_PICTURE", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCoverPhoto(_) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get("user/me/")
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateCoverPhoto(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `user/profile/change-image/${payload.get("id")}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUserProfile(context, payload) {
    context.commit("setUserProfileLoader", true);
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`user/profile/${payload.user_id}/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setUserProfileLoader", false);
            context.commit("setUserProfile", response.data);
            context.commit("updateTimeline");
            context.commit("setAccountType", response.data.account_type);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Connections
  async userRequest(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.post(`user/friends/action/`, payload)
        .then((response) => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async removeUser(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(`user/friends/action/remove/${payload}/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("REMOVE_USER", payload);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async fetchUserRequests(context, _1) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get("user/friends/requests/")
        .then((response) => {
          if (response.status === 200) {
            context.commit("setUserRequests", response.data);
            context.commit("setRequestCount", response.data.requests_count);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async sendUserRequest(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.post(`user/friends/action/request/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async handleConnctionRequest(context, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.get(`user/friends/action/request/${payload.to_user}/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setConnectionRequest", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async handleCancelRequest(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.delete(`user/friends/action/request/${payload.id}/cancel/`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getUsersList(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`user/profile/?full_name=${payload.full_name}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getArticleNotifications(context, _1) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get("notifications/")
        .then((response) => {
          if (response.status === 200) {
            context.commit("setNotifications", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getArticleNotificationsCount(context, _1) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get("notifications/unread-count/")
        .then((response) => {
          if (response.status === 200) {
            context.commit("setNotificationCount", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async markAllNotificationsAsRead(_) {
    ///setting authorization headers
    ApiService.setAuthorizationHeader();
    // calling api
    return new Promise((resolve, reject) => {
      ApiService.put(`notifications/mark-as-read/`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => reject(err));
    });
  },
  async updateNotificationsAlert(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.patch(`notifications/${payload.id}/update/`, payload)
        .then((response) => {
          if (response.status === 200) {
            // context.commit('updateNotifications', response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async deleteNotifi(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.patch(`notifications/${payload.id}/update/`,{ deleted: "true"})
        .then((response) => {
          if (response.status === 200) {
            // context.commit('updateNotifications', response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getInterestInvitation(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.get(`invite-to-interest/${payload.id}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async acceptOrRejectInterestInvitation(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(`invite-to-interest/${payload.id}/update/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async acceptOrRejectSaveArticleInvite(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/articles/save/folder/invitation/action/${payload.notification_id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async setUserSurvey(_, payload) {
    console.log(payload);
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.post(`user/profile/survey/add/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getJournalistsAndExperts(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`user/profile/special/?full_name=${payload.full_name}`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // User Interests List

  getUserInterestList(context, _) {
    // if(context.getters.getUserInterests.length == 0){
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`interests/`)
        .then((response) => {
          if (response.status === 200) {
            let interests = [];
            response.data.map((interest) => {
              const interestObj = {
                label: interest.interest,
                value: interest.id,
                id: interest.id,
              };
              interests.push(interestObj);
            });
            context.commit("setInterests", interests);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    // }
  },

  updateUserInterests(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`interests/add-or-remove/`, payload)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setUsersCategories", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteCategory(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`user-interests/${payload}/destroy/`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async craeteNewInterest(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.post(`interests/create/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateInterest(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put(`interests/${payload.id}/update/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  deleteUserInterest(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`interests/${payload}/delete/`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPhotoResume(context, payload) {
    context.commit("setPhotoResumeLoader", true);
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(
        `user/profile/photo-resume/list/?user_id=${payload.user_id}`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("setPhotoResume", response.data);
            context.commit("setPhotoResumeLoader", false);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadPhotoCheck(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("user/profile/media/add/", payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  postPhotoResume(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.post("user/profile/photo-resume/", payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
            context.commit("setPhotoResumeAfterUpload", response.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deletePhotoResume(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(`user/profile/photo-resume/${payload.id}/delete/`)
        .then((response) => {
          if (response.status === 204) {
            context.commit("updatePhotoResume", payload.id);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Fetching saved folder comments

  fetchSavedFolderComments(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`user/portfolio/articles/save/folder/${payload}/comment/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setSavedFolderComments", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Create saved folder comments

  createSaveFolderComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(
        `user/portfolio/articles/save/folder/${payload.folder_id}/comment/create/`,
        payload
      )
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Update saved folder comments

  updateSavedFolderComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(
        `user/portfolio/articles/save/folder/${payload.folder_id}/comment/update/${payload.comment_id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delete saved folder comments

  deleteSavedFolderComment(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `user/portfolio/articles/save/folder/${payload.folder_id}/comment/delete/${payload.comment_id}`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Notification on or off of Saved article folders.

  async onNotificationsSavedFolder(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/articles/save/folder/${payload}/subscribe/`
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async offNotificationsSavedFolder(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/articles/save/folder/${payload}/unsubscribe/`
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async deleteNotification(_, notification) {
    ApiService.setAuthorizationHeader();
    notification.deleted = true;
    return await new Promise((resolve, reject) => {
      ApiService.put(`/notifications/${notification.id}/update/`, notification)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Change password

  async updateCurrentPassword(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(`user/change-password/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Getting user porfolio folders

  fetchfolderCategories(context, _) {
    return new Promise((resolve, reject) => {
      ApiService.get(`user/portfolio/folder-ordering/`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Udpate ordering of portfolio folder

  async updateCategoriesOrdering(_, payload) {
    ApiService.setAuthorizationHeader();
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/folder-ordering/update/${payload.id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Update Secondary Email

  updateSecondaryEmail(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.patch("user/me/", payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Send message to admin

  sendMessage(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`ask-to-glocal/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async addEvent(context, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.post("organization-event/create/", payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async updateEvent(context, { payload, eventId }) {
    return await new Promise((resolve, reject) => {
      ApiService.put(`organization-event/${eventId}/update/`, payload)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },  
  async getAllEvent(context, { user_id, payload }) {
    // const user_id = Cookies.get("user_id");
    return await new Promise((resolve, reject) => {
      ApiService.get(`organization-event/list/?user_id=${user_id}&page=${payload.page}&ordering=-created_at&page_size=${payload.page_size}`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setEvents", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async joinEvent(context, {event_id}) {
    // const user_id = Cookies.get("user_id");
    return await new Promise((resolve, reject) => {
      ApiService.put(`organization-event/${event_id}/join/`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async leaveEvent(context, {event_id}) {
    // const user_id = Cookies.get("user_id");
    return await new Promise((resolve, reject) => {
      ApiService.put(`organization-event/${event_id}/leave/`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async deleteEvent(context, {event_id}) {
    // const user_id = Cookies.get("user_id");
    return await new Promise((resolve, reject) => {
      ApiService.delete(`organization-event/${event_id}/delete/`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  selectedEvent({ commit }, event) {
    commit('setSelectedEvent', event);
  },
  resetEvent({ commit }) {
    commit('resetSelectedEvent');
  },
};
