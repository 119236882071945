export default {
  SET_FEEDS(state, feeds) {
    state.feeds = feeds;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
};
