import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

export default {
    namespaced: true,
    state() {
        return {
          articles: [],
          searchingPDBArticles: true,
          nextUrl: "",
          previousUrl: "",
          totalArticles: 0,
          limitError: "",
          sources: "",
          searchingPdbSources: true,
          insightsSources: "",
          searchingInsightsSources: true,
          insights: [],
          searchingInsightsArticles: true,
          articleSearchHistory: {},
          savedSearchFilter: {},
          discoverArticleComments: [],
          topicComments: [],
          privateTopicComments: [],
          publicTopicComments: [],
          discussionSkeletonLoader: true,
          searchQuery: "",
          seachedTopic: "",
          startDate: "",
          endDate: "",
          insightsStartDate: "",
          insightsEndDate: "",
          includedSources: "",
          excludedSources: "",
          insightsIncludedSources: "",
          insightsExcludedSources: "",
          invitedTopicURL: "",
          topicDetails: "",
          invitedArticleId: null,
          totalResults: {},
          currentPage: 0,
          fetchedSources: [],
          updateSources: false,
          sortFilter: "",
          fetchedInsightsSources: [],
          selectedIncludeSources: [],
          selectedExcludeSources: [],
          selectedInsightsIncludeSources: [],
          selectedInsightsExcludeSources: [],
          articleReadView: false,
          filterCounts: 0,
          topicRoute: "",
          selectedSpecificSources: [],
          oldTopics: [],
          discussionCollapse: "0",
          topicSource: false,
          discoverModules: [],
          insightsNextUrl: "",
          insightsPreviousUrl: "",
          insightsTotalArticles: 0,
          insightsTotalResults: {},
          insightsCurrentPage: 0,
          insightsPageOffset: 0,
          articlePageOffset: 0,
          PDBIncludeKeywords: "",
          PDBExcludeKeywords: "",
          insightsIncludeKeywords: "",
          insightsExcludeKeywords: "",
          suggestions: [],
        };
    },
    actions,
    mutations,
    getters
}