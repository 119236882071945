

export default {
  sessions(state) {
    return state.sessions;
  },

  GET_SESSION_ID_FOR_UPDATE(state) {
    return state.chatIdForUpdate;
  },

  activeSession: (state) => state.activeSession,
  sessionChat: (state) => state.sessionChat,
  chatSectionSkeleton: (state) => state.chatSectionSkeleton,
  botResponseLoader: (state) => state.botReponseLoader,
  isDelete:(state)=>state.showDeletSession
};