import ApiService from "../../../services/api.service.js";
import JWTService from "../../../utils/Jwt.js";
import Cookies from "js-cookie";

export default {
  async login(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/login/", payload)
        .then(function(response) {
          if (response.status === 200) {
            const tokens = response.data.tokens;

            let user_id = JWTService.decodeJWT(tokens.access);

            // Set the access token and user_id in the Cookies
            Cookies.set("user_id", user_id, { expires: 365 });
            Cookies.set("access_token", tokens.access, { expires: 365 });
            Cookies.set("refresh_token", tokens.refresh);
            context.commit(
              "setUserFirstLoginAttemp",
              response.data.first_login_attempt
            );
            context.commit("setUser", {
              accessToken: tokens.access,
              user_id: user_id,
            });
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async signUp(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/create/", payload)
        .then(function(response) {
          if (response.status === 201) {
            context.commit("setEmail", payload.email);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async organizationSignUp(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/organization-account/create/", payload)
        .then(function(response) {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async checkIsAutheticated() {
    await ApiService.get("user/me/")
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async requestDemo(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("demo/create/", payload)
        .then(function(response) {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async logout(context) {
    const requestObj = {
      refresh: Cookies.get("refresh_token"),
    };
    ApiService.setAuthorizationHeader();
    await ApiService.post("user/logout/", requestObj)
      .then(() => {
        context.commit("setUser", { accessToken: null, user_id: null });
        context.commit("profile/setLoggedInUserDetails", [], { root: true });
        context.commit("profile/setUsersCategories", [], { root: true });
      })
      .catch((err) => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        Cookies.remove("user_id");
        context.commit("setUser", { accessToken: null, user_id: null });
      });
  },
  async validateEmail(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("validate/email/", payload)
        .then(function(response) {
          if (response.status === 200) {
            context.commit("setPersonalInfo", payload);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async setPasswordDetail(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("validate/password/", payload)
        .then(function(response) {
          if (response.status === 200) {
            context.commit("setPasswordInfo", payload);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  lockout(context, payload) {
    context.commit("setLockoutPassword", payload);
    return "success";
  },
  getSignupArticles() {
    return new Promise((resolve, reject) => {
      ApiService.get("articles")
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async accountDeactivate(context) {
    ApiService.setAuthorizationHeader();
    await ApiService.post("user/deactivate/")
      .then(() => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        Cookies.remove("user_id");
        context.commit("setUser", { accessToken: null, user_id: null });
      })
      .catch((err) => {
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        Cookies.remove("user_id");
        context.commit("setUser", { accessToken: null, user_id: null });
      });
  },

  async accountActivate(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch("user/activate/", payload)
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            // const tokens = response.data.tokens;
            // let user_id = JWTService.decodeJWT(tokens.access);
            // context.commit("setUser", {
            //   accessToken: tokens.access,
            //   user_id: user_id,
            // });
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async emailVerification(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`user/email-verify/?token=${payload.token}`)
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async verifyResetPassword(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/password-reset-verify/", payload)
        .then(function(response) {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async setNewPassword(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/password-reset-confirm/", payload)
        .then((response) => {
          if (response.status == 200) {
            console.log(response);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async getSubscriptionPackages(context, _1) {
    return new Promise((resolve, reject) => {
      ApiService.get(`subscriptions/`)
        .then(function(response) {
          if (response.status === 200) {
            context.commit("setSubscriptionPlans", response.data);
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async paymentCheckout(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(
        `payment/checkout/${payload.price_id}?email=${payload.email}`
      )
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },

  async inviteOnGlocal(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.post(`user/invite-to-glocal/`, payload)
        .then((response) => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getPackages(_, _1) {
    return new Promise((resolve, reject) => {
      ApiService.get(`get-subscription/`)
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async cancelGlocalSubscription(_, _1) {
    return new Promise((resolve, reject) => {
      ApiService.get(`cancel-subscription/`)
        .then(function(response) {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
  async firstLoginAttempt(_, _1) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/login-attempt/")
        .then(function(response) {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch(function(error) {
          reject(error);
        });
    });
  },
};


