import ApiService from "../../../services/api.service";
import Cookies from "js-cookie";


export default {
  async createSession(context, message) {
    ApiService.setAuthorizationHeader();

    ApiService.post("ai-chatbot-message/chat-bot-session/create/", {}).then(
      (response) => {
        context.dispatch("setActiveSession", response.data);
        context.dispatch("updateSessionList", response.data);
        context.dispatch("createMessage", {
          chat_bot_session: response.data.id,
          message: message,
        });
      }
    );
  },

  async creatingSession(context, payload) {
    context.dispatch("createMessage", {
      chat_bot_session: payload.id,
      message: payload.message,
    });
  },

  setActiveSession({ commit, dispatch }, session) {
    commit("SET_ACTIVE_SESSION", session);
    if (session) {
      dispatch("connectBot", session.id);
    }
  },

  getMessages({ commit }, id) {
    return new Promise((resolve, reject) => {
      ApiService.get(`ai-chatbot-message/${id}/list/?ordering=created_at`)
        .then((res) => {
          if (res.status === 200) {
            commit("SET_SESSION_CHAT", res.data.results);
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async initiateSessions(context) {
    let sessionList = await ApiService.get(
      "ai-chatbot-message/chat-bot-session/list/?ordering=-created_at"
    );
    context.state.sessions = sessionList.data.results;
  },
  updateSessionList({ state }, session) {
    state.sessions.unshift(session);
  },

  createMessage({ commit, dispatch }, message) {
    return new Promise((resolve, reject) => {
      ApiService.post(`ai-chatbot-message/create/`, message)
        .then((res) => {
          if (res.status === 201) {
            commit("UPDATE_SESSION_CHAT", res.data);
            dispatch("toggleBotResponseLoader");
            resolve();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async editSessionName({ state, commit }, session) {
    await ApiService.patch(
      `ai-chatbot-message/chat-bot-session/${session.id}/update/`,
      {
        name: session.name,
      }
    );
    commit("SET_SESSION_ID_FOR_UPDATE", session.id);
    commit("EDIT_SESSION_NAME", session.name);
  },

  async deleteSession({ state, commit }, session) {
    try {
      commit("DELETE_SESSION", session.id);
      const res= await ApiService.delete(
        `ai-chatbot-message/chat-bot-session/${session.id}/delete/`
      );
      if (res.status===204) {
        commit("SESSION_DELETE", false);
        commit('CLEAR_SESSIONCHAT');
        commit('SET_ACTIVE_SESSION',null);
      }
    } catch (error) {
      console.log('error',error)
    }
  },

  connectBot({ state, commit, dispatch }, sessionId) {
    const token = Cookies.get("access_token");
    const socketUrl = `${process.env.VUE_APP_ROOT_WEBSOCKET}aibot-message/${sessionId}/?token=${token}`

    state.socket = new WebSocket(socketUrl);
    state.socket.onopen = () => {
      state.socket.onmessage = (event) => {
        let AIresponse = JSON.parse(event.data);
        // updating title
        const session = state.sessions.find(
          (session) => session.id === state.activeSession.id
        );
        const sessionPositionIndex = state.sessions.findIndex(
          (session) => session.id === state.activeSession.id
        );
        if (AIresponse.title && session && sessionPositionIndex !== -1) {
          session.name = AIresponse.title;
          state.sessions[sessionPositionIndex] = { ...session };
        }
        if (
          AIresponse.total_threads &&
          session &&
          sessionPositionIndex !== -1
        ) {
          session.total_threads = AIresponse.total_threads;
          state.sessions[sessionPositionIndex] = { ...session };
        }
        dispatch("toggleBotResponseLoader");
        // push the response of bot into the chat
        commit("UPDATE_SESSION_CHAT", AIresponse.data);
      };
    };
  },
  emptySectionChats({ commit }) {
    commit("SET_SESSION_CHAT", []);
  },
  toggleChatSectionSkeleton({ commit, state }) {
    commit("TOGGLE_CHAT_SETION_SKELETON", !state.chatSectionSkeleton);
  },
  toggleBotResponseLoader: ({ commit, state }) => { commit('TOGGLE_BOT_RESPONSE_LOADER', !state.botReponseLoader) },
  sendSessionInvitation(_, payload) { 
    const sessionId = payload.sessionId
    delete payload.sessionId
    // payload.link = `${process.env.VUE_REDIRECTION_URL}/glocal-AI/${sessionId}`;
    return new Promise((resolve, reject) => { 
      ApiService.post(`ai-chatbot-message/chat-bot-session/${sessionId}/invite/`, payload).then(res => { 
          resolve()
      })
        .catch(err => { reject()})
    })
  }
};
