export default {
  getDiscussionComments(state) {
    return state.topicComments;
  },
  getPrivateDiscussionComments(state) {
    return state.privateTopicComments;
  },
  getPublicDiscussionComments(state) {
    return state.publicTopicComments;
  },
  getchatsSkeletonLoader(state) {
    return state.chatsSkeletonLoader;
  },
  getNextUrl(state) {
    return state.nextUrl;
  },
  getTopicChats(state) {
    return state.topicChats;
  },
  privateTopicDiscussionUsers: state => state.privateDiscussionUsers,
  publicTopicDiscussionUsers: state => state.publicDiscussionUsers,
  discoverTopicDiscussionLoader: state => state.discoverTopicDiscussionLoader,
  selectedDiscussionArticleId: state => state.selectedDiscussionArticleId
};
