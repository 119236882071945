import Cookies from "js-cookie";

export default {
    isAuthenticated(state) {
        return !!Cookies.get("access_token");
    },
    getLockoutPassword(state) {
        return state.lockOutPassword;
    },
    getUserId(state){
        return state.user_id;
    },
    getFirstLoginAttemp(state){
        return state.firstLoginAttemp;
    },
    getEmail(state){
        return state.email;
    },
    getSubscriptionPackagesModalVisibility(state){
        return state.subscriptionPackagesModal;
    },
    getSubscriptionPlans(state){
        return state.subscriptionPlans;
    }

}