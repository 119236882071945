import { createStore } from "vuex";
import authModule from "./modules/auth/index.js"
import editor from "./modules/editor/index.js";
import article from "./modules/article/index.js";
import profile from "./modules/profile/index";
import impact from "./modules/Impact/index.js";
import tagify from "./modules/tagify/index.js";
import discover from "./modules/discover/index.js"
import payment from "./modules/payment";
import folders from "./modules/folders/index.js";
import discussion from "./modules/discussion/index.js";
import aiChat from "./modules/aiChat/index.js";
import feed from "./modules/feed/index.js"
import location from "./modules/location/index.js";

const store = createStore({
  modules: {
    auth: authModule,
    editor: editor,
    article: article,
    profile: profile,
    impact: impact,
    tagify: tagify,
    discover: discover,
    payment,
    folders: folders,
    discussion: discussion,
    aiChat: aiChat,
    feed: feed,
    location: location,
  },
});

export default store;