export default {
  SET_DISCUSSION_COMMENTS(state, payload) {
    state.topicComments = payload;
  },
  SET_PRIVATE_DISCUSSION_COMMENTS(state, payload) {
    state.privateTopicComments = payload;
  },
  SET_PUBLIC_DISCUSSION_COMMENTS(state, payload) {
    state.publicTopicComments = payload;
  },
  SET_TOPICS_CHATS(state, payload) {
    state.topicChats = payload;
  },
  SET_NEXT_URL(state, payload) {
    state.nextUrl = payload;
  },
  SET_CHATS_LOADER(state, payload) {
    state.chatsSkeletonLoader = payload;
  },
  UPDATE_PRIVATE_DISCUSSION: (state,comment)=>{state.privateTopicComments.push(comment)},
  UPDATE_PUBLIC_DISCUSSION: (state,comment)=>{state.publicTopicComments.push(comment)},
  SET_PUBLIC_DISUCSSION_USERS: (state, users) => state.publicDiscussionUsers = users,
  SET_PRIVATE_DISUCSSION_USERS: (state, users) => state.privateDiscussionUsers = users,
  TOGGLE_DICOVER_TOPIC_DISCUSSION_LOADER: (state, toggle) => {state.discoverTopicDiscussionLoader = toggle},
  // DELETE_COMMENT_PRIVATE_DISCUSSION: (state,comment)=>{
    
  //   state.privateTopicComments
  // },
  // DELETE_COMMENT_PUBLIC_DISCUSSION: (state,comment)=>{state.publicTopicComments.push(comment)},
  EDIT_COMMENT_PRIVATE_DISCUSSION: (state,comment)=>{
   const commentIndex =  state.privateTopicComments.findIndex(foundComment => foundComment.id === comment.id || foundComment.id === comment.parent)
   if(commentIndex >=0 ){
  
    if(comment.parent){
      const childIndex = state.privateTopicComments[commentIndex].child.findIndex(foundComment => foundComment.id === comment.id )
      state.privateTopicComments[commentIndex].child[childIndex] = {...comment}
    }
    else{
      state.privateTopicComments[commentIndex] = {...comment}
    }
   }
  },
  EDIT_COMMENT_PUBLIC_DISCUSSION: (state,comment)=>{

    const commentIndex =  state.publicTopicComments.findIndex(foundComment => foundComment.id === comment.id || foundComment.id === comment.parent)

    if(commentIndex >=0 ){

    if(comment.parent){
      const childIndex = state.publicTopicComments[commentIndex].child.findIndex(foundComment => foundComment.id === comment.id )
      state.publicTopicComments[commentIndex].child[childIndex] = {...comment}
    }
    else{
      state.publicTopicComments[commentIndex] = {...comment}
    }
   }
  },
  DELETE_COMMENT_PRIVATE_DISCUSSION: (state,comment)=>{
    const commentIndex =  state.privateTopicComments.findIndex(foundComment => foundComment.id === comment.discussion_id|| foundComment.id === comment.parent)
    if(commentIndex >=0 ){
   
     if(comment.parent){
       const childIndex = state.privateTopicComments[commentIndex].child.findIndex(foundComment => foundComment.id === comment.discussion_id )
       state.privateTopicComments[commentIndex].child.splice(childIndex,1)
     }
     else{
      state.privateTopicComments.splice(commentIndex,1)
     }
    }
   },
   DELETE_COMMENT_PUBLIC_DISCUSSION: (state,comment)=>{
 
    const commentIndex =  state.publicTopicComments.findIndex(foundComment => foundComment.id === comment.discussion_id || foundComment.id === comment.parent)
    if(commentIndex >=0 ){
   
     if(comment.parent){
       const childIndex = state.publicTopicComments[commentIndex].child.findIndex(foundComment => foundComment.id === comment.discussion_id )
       state.publicTopicComments[commentIndex].child.splice(childIndex,1)
     }
     else{
      state.publicTopicComments.splice(commentIndex,1)
     }
    }
   },
   SET_SELECTED_DISCUSSION_ARTICLE_ID: (state, articleId) =>{ state.selectedDiscussionArticleId = articleId }

};