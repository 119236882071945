import { context } from "ant-design-vue/lib/vc-image/src/PreviewGroup";
import ApiService from "../../../services/api.service";

export default {
  async fetchInterests(context) {
    await ApiService.get("interests/")
      .then((response) => {
        context.commit("setInterests", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  fetchLanguages(context) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.get(`languages/list/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setFetchedLanguages", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getGlocalFeatures(context) {
    if (context.getters.getGlocalFeatures.length == 0) {
      return await new Promise((resolve, reject) => {
        ApiService.setAuthorizationHeader();
        ApiService.get("glocal-features")
          .then((response) => {
            if (response.status === 200) {
              context.commit("setFeatures", response.data);
              resolve(response);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  async fetchUserRatedArticle(context, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get(
        `user/portfolio/rated-articles/list/${payload.user_id}/?limit=${payload.limit}&offset=${payload.offset}`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("setRatedArticles", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async fetchUserShareArticle(context, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get(
        `user/portfolio/articles/share/list/${payload.user_id}/?page=${payload.page}&page_size=${payload.page_size}`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("setShareArticles", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async shareArticle(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.post("user/portfolio/articles/share/", payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Save article with folder in portfolio

  // Get all user saved articles folder
  async saveArticleFolder(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.post("user/portfolio/articles/save/folder/create/", payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // This api is used for update folder and and we can add new article in same fodler.
  async updateArticleFolder(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.patch(
        `user/portfolio/articles/save/folder/${payload.folder_id}/update/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delete saved article folders
  async deleteArticleFolder(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.delete(
        `user/portfolio/articles/save/folder/${payload}/delete/`,
        payload
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Save article folders
  async fetchSaveArticleFolder(context, _) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get(`user/portfolio/articles/save/folder/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setSaveArticleFolders", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Joined article folders
  async fetchJoinedSaveArticleFolder(context, _) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get(`user/portfolio/articles/save/folder/joined-folders/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setJoinedSaveArticleFolders", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // user saved articles list
  async fetchSavedArticlesList(context, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.setAuthorizationHeader();
      ApiService.get(
        `user/portfolio/articles/save/folder/${payload.folder_id}/articles/list/`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("setSavedArticles", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delte saved article from list
  async deleteSavedArticle(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/articles/save/folder/${payload.id}/remove-save-article/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Delete joined article folders
  async deleteJoinedArticleFolder(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/articles/save/folder/${payload}/remove-join-folder/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Invite saved article folder to others
  async inviteToOthersOnFolders(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/articles/save/folder/${payload.id}/invite-to-folder/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async shareArticleWithConnections(_, payload) {
    return await new Promise((resolve, reject) => {
      ApiService.post("discover/share/", payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async deleteShareArticle(context, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `user/portfolio/articles/share/${payload.article_id}/delete`
      )
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async getNotificationsArticleData(_, payload) {
    return await new Promise((resolve, reject) => {
      // ApiService.setAuthorizationHeader();
      ApiService.get(`article-data/${payload}/`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  topicGroupDetails(context, payload) {
    context.commit("discussion/SET_CHATS_LOADER", true, {
      root: true,
    });
    return new Promise((resolve, reject) => {
      ApiService.get(`interest-group/${payload.interest_id}/detail/`)
        .then((response) => {
          if (response.status === 200) {
            context.commit("setInterestGroup", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  submitInviteCategory(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`invite/`, payload)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  inviteOnTopic(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`multiple-invitation-to-category/${payload.interest_group_id}/`, payload.list)
        .then((response) => {
          if (response.status === 201) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchArticleReactedUsers(_, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get(`discover/article/${payload.articleId}/react/`)
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  articleReaction(context, payload) {
    const reactionPayload = {
      reaction: payload.reaction,
    };
    return new Promise((resolve, reject) => {
      ApiService.post(
        `discover/article/${payload.articleId}/react/create/`,
        reactionPayload
      )
        .then((response) => {
          if (response.status === 201) {
            context.commit("articleReaction", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
    // ApiService.post('/discover/article/${payload.articleId}/react/create').then(response => {
    //   if (response.status === 201)
    //     resolve(response)
    // })
    // console.log(payload);
    // return new Promise((resolve, reject) => {
    //   ApiService.post(`invite/`, payload)
    //     .then((response) => {
    //       if (response.status === 201) {
    //         resolve(response)
    //       }
    //     }).catch((err) => {
    //       reject(err);
    //     })
    // })
  },

  deleteArticleReaction(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete(
        `discover/article/${payload.articleId}/react/${payload.reactionId}/delete/`
      )
        .then((response) => {
          if (response.status === 200) {
            context.commit("articleReaction", response.data);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  async removeAdminFromTopic(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `interest-group/${payload.id}/promote-or-demote/${payload.user_id}/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Remove user from save article folder
  async removeUserFromFolder(_, payload) {
    ApiService.setAuthorizationHeader();
    return new Promise((resolve, reject) => {
      ApiService.put(
        `user/portfolio/articles/save/folder/${payload.id}/revoke-folder-access/`,
        payload
      )
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};